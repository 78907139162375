import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { Wrapper } from "../../styles";
import { Button } from "../Button";

const SignUpBanner = () => {
    return (
      <Container style={{ background: '#eee' }}>
        <Wrapper>
            <div style={{ textAlign: 'center' }}>
              <h2 className="blue-text">
                Become a Rachio Pro
              </h2>
              <p>
              Get exclusive access to rewards and dedicated support.
              </p>
              <Button as={Link} to="/signup/" id="pro-signup-top">
                Get Started
              </Button>
            </div>
        </Wrapper>
      </Container>
    )
}

const Container = styled.section`
  padding: var(--baseSectionPadding);
  h2 {
    margin-top: 0;
  }
`

export default SignUpBanner;