import React from "react"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import { motion } from "framer-motion"
import styled from "styled-components"
import { GatsbyImage, getImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image"
import { Grid, Wrapper, Container, media } from "../styles"
import { Button } from "../components/Button"
import Icon from "../components/Icon"
import CompareTableSimple from "../components/CompareTableSimple"
import loadable from '@loadable/component'
import { SplitBanner } from "../components/banners/SplitBanner"
import SignUpBanner from "../components/banners/SignUpBanner"
import { LoadingSpinner } from "@rachio-npm/rachio-retail-common"
const DistributorMap = loadable(() => /* webpackChunkName: "[request]" */ import("../components/DistributorMap"), {
  fallback: <LoadingSpinner />,
});

const IndexPage = ({ data }) => (
  <>
    <SEO
      title="Partner with the Wifi Sprinkler Controller Leader"
      pathname="/"
    />
    <Hero data-testid="hero">
      <Grid cols={[1, 1, 2]} css="grid-gap: 0">
        <div className="hero-content">
          <h6>Available Now in Distribution</h6>
          <h1>Rachio's first controller for pros</h1>
          <p>The NEW Pro Series Wi-Fi Irrigation Controller provides a professional-grade indoor installation experience for a variety of applications. Optional weatherproof enclosure available for outdoor installs.</p>
          <Button
            as={Link}
            to="https://www.youtube.com/watch?v=ioFjM5I3hRg"
            id="pro-features-demo"
            color="transparent"
          >
            Pro features demo
          </Button>
        </div>
        <RightContainer>
          <div className="hero-img" css="margin:0 auto;">
            <GatsbyImage
              image={getImage(data.lightbarImg.childImageSharp.gatsbyImageData) as IGatsbyImageData}
              alt="Rachio 3 Pro Series controller"
            />
          </div>
        </RightContainer>
      </Grid>
    </Hero>

    <FeatureBanner>
      <li>
        <Icon name="circlecheck" color="var(--navyBlue)"/>
        <p css="margin-top: 0;">No recurring software fees</p>
      </li>
      <li>
        <Icon name="circlecheck" color="var(--navyBlue)"/>
        <p css="margin-top: 0;">Supports 2.4 / 5 GHz routers</p>
      </li>
      <li>
        <Icon name="circlecheck" color="var(--navyBlue)"/>
        <p css="margin-top: 0;">Faulty wiring alerts</p>
      </li>
      <li>
        <Icon name="circlecheck" color="var(--navyBlue)"/>
        <p css="margin-top: 0;">4-year warranty</p>
      </li>
    </FeatureBanner>

    <SplitBanner
      left={
        <>
          <div className="features-content">
            <h2>Key features</h2>
            <ul>
              <li>Wire-ready terminals (8 or 16-zone)</li>
              <li>1 MV & 2 sensors for flow and rain</li>
              <li>Supports 2.4 / 5 GHz routers</li>
              <li>Faulty wiring alerts</li>
              <li>4-year warranty</li>
            </ul>
            <div style={{ marginTop: '15px' }}>
              <Button
                as={Link}
                to="https://rachio-media.s3.us-west-2.amazonaws.com/downloads/R3ProSeries_2024_0617.pdf"
                id="pro-download-specs"
              >
                Download specs
              </Button>
            </div>
          </div>
        </>
      }
      right={
        <>
          <div className="features-content" css="margin:0 auto;">
            <GatsbyImage
              image={getImage(data.terminalsImg.childImageSharp.gatsbyImageData) as IGatsbyImageData}
              alt="Rachio 3 Pro Series controller"
            />
          </div>
        </>
      }
    />

    <SplitBanner
      className="navy-bg"
      flipMobile={true}
      left={
        <>
          <div style={{ margin: '0 auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <StaticImage
              src="https://cdn.sanity.io/images/1d0v1es1/production/bc50d489480ed7292d92334a33708494b61092e9-959x1751.png?w=959&h=1751&auto=format"
              alt="Rachio 3 Pro Series controller"
              style={{ maxWidth: '480px' }}
            />
          </div>
        </>
      }
      right={
        <>
          <div className="features-content  white-text">
            <h2>Do more with the Rachio app</h2>
            <ul>
              <li>Access and troubleshoot controllers anywhere with the Rachio app</li>
              <li>Pro Series Controllers are eligible for <Link to="/pro-rewards/">Pro Rewards Program</Link></li>
              <li>Pro features to simplify your workday</li>
              <li>Dedicated English and Spanish pro support line</li>
              <li>Supports 2.4 / 5 GHz routers</li>
              <li>No recurring software fees</li>
            </ul>
          </div>
        </>
      }
    />

    <DistributorMapSection id="distributor-finder">
      <Wrapper>
        <div className="center-text">
          <Link to="/where-to-buy/">
            <h2 className="blue-text">
              Where to Buy
            </h2>
          </Link>
          <p>
            Rachio partners with irrigation distributors to provide in-store same-day pickup and education.
            We encourage all contractors to establish an account and relationship with their local irrigation
            supplier which provides the best pricing, availability, purchase terms, and rewards.
          </p>
          <p>
            Can’t find Rachio near you? <a href="mailto:prosupport@rachio.com">Tell us where you want to buy</a>.
          </p>
        </div>
        <DistributorMap />
      </Wrapper>
    </DistributorMapSection>

    <EmailBanner>
      <div className="email-content">
        <div css="padding: 150 0;">
          <EmailGrid>
            <div>
              <h2>Sign up for updates</h2>
              <p>Enter your email to be notified when the Pro Series launches.</p>
            </div>
            <div>
              <form action="https://rachio.us3.list-manage.com/subscribe/post?u=0dc374f8eb7f69783653719a5&amp;id=23d167976b" method="post" target="_blank">
                <label>
                  <input type="email" name="EMAIL" id="mce-EMAIL" placeholder="Email Address" />
                </label>
                <button type="submit" name="subscribe" id="mc-embedded-subscribe">
                  <Icon name="caret" color="var(--navyBlue)" />
                </button>
              </form>
            </div>
          </EmailGrid>
        </div>
      </div>
    </EmailBanner>

    <ComparisonChart>
      <h2>Rachio Pro Series vs. consumer controller</h2>
      <p>
        Pro Series controller provides a professional-grade Wi-Fi irrigation
        solution to maximize water efficiency. Do more for your customer with
        the smartest weather data, simplest app, and fastest install.
      </p>
      <CompareTableSimple />
    </ComparisonChart>

    <SignUpBanner />
  </>
)

export default IndexPage

const Hero = styled.div`
  position: relative;
  overflow: hidden;
  background-color: var(--navyBlue);
  .hero-content {
    color: var(--white);
    padding: var(--basePadding);
    z-index: 1;
  }
  ${media.medium`
    .hero-content {
      padding: var(--topSectionPadding) 1rem var(--bottomSectionPadding) var(--headerPadding);
    }
    .hero-img {
      padding: var(--topSectionPadding) 1rem var(--bottomSectionPadding) 1rem;
    }
  `}
  h1{
    margin-top: 0;
  }
`
const RightContainer = styled(motion.div)`
  z-index: 1;
  // display: flex;
  // justify-content: space-evenly;
  // align-items: flex-end;
  padding: var(--basePadding);
`
const FeatureBanner = styled.ul`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: var(--basePadding);
  background: var(--lightesterGray);
  color: var(--navyBlue);
  li {
    flex: 1;
    margin: 2rem;
    a {
      height: 48px;
      width: 48px;
      svg {
        height: inherit;
        width: inherit;
      }
    }
  }
  ${media.small`
    justify-content: "space-between";
    flex-direction: row;
    align-items: flex-start;
  `};
`

const DistributorMapSection = styled.div`
  padding: var(--baseSectionPaddingShort) calc(var(--basePadding) / 8);
  background: var(--lightesterGray);

  h2 {
    margin-top: 0;
  }

  ${media.tablet`
    padding: calc(var(--baseSectionPadding) / 2) var(--baseSectionPadding);
  `}
`

const EmailBanner = styled.div`
  overflow: hidden;
  background: var(--navyBlue);
  color: white;

  .email-content{
    margin: 0 auto;
    padding: var(--baseSectionPadding);

    h2{
      margin-top: 0;
      font-size: 3.6rem;
    }

  }
`

const ComparisonChart = styled.div`
  padding: var(--baseSectionPaddingShort);

  h2, p {
    text-align: center;
  }

  h2 {
    color: var(--navyBlue);
    margin: 0;
  }

  table {
    max-width: 992px;
    margin: 0 auto;
  }
`

const EmailGrid = styled.div`
  display: grid;
  grid-gap: var(--basePadding);
  margin: 0px auto;
  grid-template-columns: repeat(1, 1fr);

  form {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: normal;
    justify-content: normal;
    align-items: normal;
    padding-left: 2px;
    overflow: hidden;
    box-sizing: inherit;

    label{
      width: 100%;
      display: inline-block;
      margin: 0px;

      input{
        background: var(--white);
        border: 1px solid var(--lineColor);
        border-radius: var(--inputRadius);
        color: var(--darkerGray);
        display: block;
        font-size: var(--baseFontSize);
        margin: 1.5rem 0 0 0;
        max-width: 100%;
        padding: 2.3rem 2rem;
        width: 100%;
      }
    }

    button{
      width: 50px;
      height: 50px;
      min-width: 50px;
      border-radius: 100%;
      box-shadow: var(--elevation-2);
      background-color: var(--white);
      margin: 2.5rem 2rem;

      svg{
        transform: rotate(-90deg);
        transition: var(--transitionSpeed) ease-in-out transform;
      }
    }
  }

  ${media.medium`
    grid-template-columns: repeat(2, 1fr);
  `}
`

// const IconList = styled.ul`
//   li {
//     display: flex;
//     align-items: center;
//     svg {
//       flex: none;
//       height: 24px;
//       width: 24px;
//       margin-right: 1rem;
//     }
//   }
// `

export const query = graphql`
  query {
    lightbarImg: file(relativePath: { eq: "pages/home/ProSeries-1@2x.png"}){
      ...smallFluidImage
    }
    terminalsImg: file(relativePath: { eq: "pages/home/rachio-proseries-terminals.png"}){
      ...fullFluidImage
    }
  }
`
