import React from "react";
import styled from "styled-components"
import { below, Grid, media } from "../../styles"

type Props = { 
  left: JSX.Element, 
  right: JSX.Element,
  className?: string,
  flipMobile?: boolean
}
export const SplitBanner: React.FC<Props> = ({ left, right, className, flipMobile }) => {
    return (
      <Wrapper className={className}>
        <Grid cols={[1, 1, 2]}>
          <LeftInnerWrap flipMobile={flipMobile}>
            {left}
          </LeftInnerWrap>
          <RightInnerWrap flipMobile={flipMobile}>
            {right}
          </RightInnerWrap>
        </Grid>
      </Wrapper>
    )
}

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  .features-content {
    color: var(--navyBlue);
    padding: var(--basePadding);
    z-index: 1;
    h2{
      margin: var(--basePadding) 1rem var(--basePadding) 0;
    }

    ul{
      padding: 0 0 0 2rem;
    }
    ul li{
      list-style-type: disc;
      padding: 1rem;
    }
  }
  ${media.medium`
    .features-content {
      padding: var(--topSectionPadding) 1rem var(--bottomSectionPadding) var(--headerPadding);
    }
  `}
`

const LeftInnerWrap = styled.div<{ flipMobile?: boolean }>`
  z-index: 1;
  padding: var(--basePadding);
  display: flex;
  justfiy-content: center; 
  margin: 0 auto;
  align-items: center;
  ${({ flipMobile }) => below.medium`
    order: ${flipMobile ? 2 : 1}
  `}
`

const RightInnerWrap = styled.div<{ flipMobile?: boolean }>`
  z-index: 1;
  padding: var(--basePadding);
  display: flex;
  justfiy-content: center; 
  margin: 0 auto;
  align-items: center;
  ${({ flipMobile }) => below.medium`
    order: ${flipMobile ? 1 : 2}
  `}
`

